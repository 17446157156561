import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import useSanityQuery from '~/client/useSanityQuery'

import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import client from '~/client'
import useCustomPrices from '~/routes/Admin/Company/Pricing/useCustomPrices'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import sharedProps from '~/components/sharedProps'
import useCompanyDetails from '~/routes/Admin/Company/Profile/useCompanyDetails'

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const currencyUSDFormatter = ({ value }) => {
  const usd = currencyFormatter.format(value)
  if (isNaN(value)) return ''
  return usd
}

// const markupFormatter = ({value}) =>

export default function PricingTable({ companyId: propsCompanyId } = {}) {
  const userInfo = client.getUserInfo()
  const companyId = propsCompanyId || userInfo.companyId

  const [search, setSearch] = React.useState('')

  const query = `*[_type == 'products'   
  ${
    search?.length > 1
      ? `&& [modelNumber, title, spec.category] match '*' + '${search}' + '*'`
      : ''
  }
  ]
  // [0...10]
  { modelNumber, title, "category": spec.category, "price": price.global.USD }`

  const { data: sanityProducts = [], isLoading: isLoadingSanity } =
    useSanityQuery(query)

  const {
    data: customProducts,
    saveCustomPrice,
    deleteCustomPrice,
    isLoading: isLoadingCustom,
  } = useCustomPrices({ companyId })

  const isLoading = isLoadingCustom || isLoadingSanity

  const combinedProducts = sanityProducts.map(sanityProduct => {
    const customProduct = customProducts?.find(
      customProduct => sanityProduct.modelNumber === customProduct.productId
    ) || {
      info: { price: {} },
    }
    return { ...sanityProduct, customProduct }
  })

  const { data } = useCompanyDetails()

  const apiRef = React.useRef()

  // console.log('%ccustomProducts', 'color:blue', customProducts)
  // console.log('%ccombinedProducts', 'color:green', combinedProducts)

  // React.useEffect(() => {
  // customProducts?.forEach(price => deleteCustomPrice(price))
  // }, [customProducts])

  return (
    <Card {...sharedProps.card}>
      <Stack
        spacing={2}
        direction="row"
        sx={{ justifyContent: 'space-between' }}
      >
        <Typography variant="h3" gutterBottom>
          Set product prices
        </Typography>
        <TextField
          placeholder="Search products"
          onChange={e => setSearch(e.target.value)}
          value={search}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearch('')} edge="end">
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <LoadingIndicators {...{ isLoading }} />

      {combinedProducts && (
        <Box
          sx={{
            height: 400,
            mt: 3,
            'input[aria-invalid="true"]': { color: 'error.main' },
          }}
        >
          <DataGrid
            apiRef={apiRef}
            autoPageSize
            rows={combinedProducts}
            getRowId={row => row.modelNumber}
            onRowClick={({ id }, e, details) => {
              e.stopPropagation()
              e.defaultMuiPrevented = true
              apiRef.current.startCellEditMode({ id, field: 'customPrice' })
            }}
            // onCellE
            // onCellEditStop={handleCellEditStop}
            processRowUpdate={(row, previousRow) => {
              const { customProduct, title, modelNumber } = row
              // const userInfo = client.getUserInfo()

              if (customProduct.info.price.USD === undefined) return row

              if (customProduct.info.price.USD < 0) {
                console.log('ERROR PRICE', previousRow)
                return previousRow
              }

              // this makes it all again because safer if new?
              const newCustomProduct = {
                companyId: companyId,
                productId: modelNumber,
                customPriceId: `${companyId}-${modelNumber}`,
                info: { ...customProduct.info, title },
              }

              if (customProduct.info.price.USD === null) {
                deleteCustomPrice(newCustomProduct)
                return { ...row, customProduct: { info: { price: {} } } }
              }

              saveCustomPrice(newCustomProduct)
              return { ...row, customProduct: newCustomProduct }

              // return row
            }}
            columns={[
              {
                field: 'modelNumber',
                headerName: 'Model number',
                minWidth: 200,
              },
              {
                field: 'title',
                headerName: 'Title',
                minWidth: 200,
              },
              {
                field: 'price',
                headerName: 'Unico list price',
                type: 'number',
                valueFormatter: currencyUSDFormatter,
                minWidth: 100,
              },
              {
                field: 'markupPrice',
                headerName: 'Sell to contractor price',
                type: 'number',
                valueGetter: params =>
                  params.row.price * (data?.distributorMultiplier || 1),
                valueFormatter: currencyUSDFormatter,
                minWidth: 100,
              },
              {
                field: 'customPrice',
                headerName: 'Custom price',
                editable: true,
                type: 'number',
                valueGetter: params =>
                  // console.log('PRAMSZS', params) ||
                  params.row.customProduct?.info?.price?.USD,
                valueSetter: ({ row, value }) => {
                  const updatedRow = { ...row }
                  updatedRow.customProduct.info.price.USD = value
                  return updatedRow
                },
                preProcessEditCellProps: params => ({
                  ...params.props,
                  error: params.props.value < 0,
                }),
                valueFormatter: currencyUSDFormatter,
                minWidth: 100,
              },
              // {
              //   width: 48,
              //   sortable: false,
              //   field: 'action',
              //   headerName: '',
              //   renderCell: ({ id, ...params }) => {
              //     const onClick = e => {
              //       e.stopPropagation() // don't select this row after clicking
              //       params.api.startCellEditMode({ id, field: 'customPrice' })
              //     }

              //     return (
              //       <IconButton onClick={onClick}>
              //         <Edit />
              //       </IconButton>
              //     )
              //   },
              // },
              // {
              //   field: 'customPrice',
              //   headerName: 'Custom price',
              //   renderCell: params => <CustomPriceCell {...params} />,
              //   // editable: true,
              // },
            ]}
          />
        </Box>
      )}
    </Card>
  )
}
