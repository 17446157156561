import { MultiSelectElement, TextFieldElement } from 'react-hook-form-mui'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'

import { multiSelectElementProps } from '~/components/Data/Filter/SearchContainer'
import { companyTypeOptions } from '../../Companies/companyTypes'
import isDev from '../../../config/isDev'

export default function CompanySearchFields() {
  return (
    <>
      <TextFieldElement label="Company name" name="companyName" />
      {isDev() && (
        <TextFieldElement
          label="Distributor parent name"
          name="distributorCompany.companyName"
        />
      )}
      <MultiSelectElement
        {...multiSelectElementProps}
        name="companyType"
        label="Company type"
        options={companyTypeOptions}
      />
      <MultiSelectElement
        {...multiSelectElementProps}
        name="status"
        label="Status"
        options={[
          { id: 'active', label: 'Active' },
          { id: 'disabled', label: 'Disabled' },
        ]}
      />
      <DatePickerElement name="createdAt.gte" label="Created after" />
      <DatePickerElement name="createdAt.lte" label="Created before" />
    </>
  )
}
