import { Box, Card, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
// import Grid from '@mui/material/Unstable_Grid2/Grid2'
// import Table from '@mui/material/Table'
import { Forest, People } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { TextFieldElement } from 'react-hook-form-mui'
import client from '~/client'
import AddCompanyForm from '~/components/Companies/AddCompanyForm'
import AutoTable, {
  ChipCell,
  DateCell,
  DisableCell,
  TableCell,
} from '~/components/Data/AutoTable'
import SearchContainer from '~/components/Data/Filter/SearchContainer'
import Drawer from '~/components/Layouts/Drawer'
import PageHeader from '~/components/Layouts/PageHeader'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import useSearchParams from '~/core/router/useSearchParams'
import flexSplitSx from '~/core/sx/flexSplitSx'
import { useDistributorNetworkCompanies } from '../../../../components/Companies/useNetworkCompanies'

export default function DistributorBranch() {
  const params = useParams()

  const [search, setSearch] = React.useState()
  const { data, deleteCompany, ...response } = useDistributorNetworkCompanies({
    search,
  })

  //GET {{host}}/repCompanyRelations/companies?pageSize=10&search={"contractingFirm": {"companyType": "distributor"} } HTTP/1.1

  const branches = data
  // ?.map(({ contractingFirm, ...relation }) => ({
  //   ...contractingFirm,
  //   // distributorCompanyName: relation.distributorCompany.companyName,
  // }))
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const userInfo = client.getUserInfo()

  return (
    <>
      <PageHeader />
      <Card sx={{ p: 2.5 }}>
        <Box sx={flexSplitSx}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Branch
          </Typography>
          <Button
            onClick={() =>
              navigate(`?addCompany`, {
                state: {
                  companyType: 'distributor',
                  distributorCompanyId: userInfo.companyId,
                },
              })
            }
            endIcon={<Forest />}
          >
            Add new branch
          </Button>
        </Box>
        <SearchContainer setSearch={setSearch}>
          {/* <CompanySearchFields /> */}
          <TextFieldElement label="Company name" name="companyName" />
        </SearchContainer>
        <AutoTable
          {...response}
          data={branches}
          titleField="companyName"
          uniqueField="companyId"
          columns={[
            { field: 'createdAt', Component: DateCell, headerName: 'Created' },
            {
              field: 'distributorCompanyName',
              headerName: 'Parent name',
              Component: props => (
                <TableCell>{props.data.distributorCompanyName}</TableCell>
              ),
            },
            { field: 'companyName', headerName: 'Company name' },
            'relationshipPinCode',
            { field: 'companyType', Component: ChipCell },
            { field: 'status', Component: ChipCell },
            // {
            //   field: 'edit',
            //   Component: EditCell,
            //   // to: 'editCompany='
            //   onClick: branch => navigate(`?editCompany=${branch.companyId}`),
            // },
            ...(client.hasRoles([
              'distributor_company_admin',
              'distributor_company_user',
            ])
              ? [
                  {
                    field: 'viewBranch',
                    Component: ({ data, ...rest }) => (
                      <TableCell sx={{ px: '0 !important' }}>
                        <Link to={`./${data.companyId}`} state={data}>
                          <Button
                            {...sharedProps.button.table}
                            startIcon={<People />}
                          >
                            Edit
                          </Button>
                        </Link>
                      </TableCell>
                    ),
                  },
                  {
                    field: 'disable',
                    Component: DisableCell,
                    width: 0.5,
                    onClick: deleteCompany,
                  },
                ]
              : client.hasRoles(['rep_sales_manager', 'rep_admin'])
              ? [
                  {
                    field: 'editUsers',
                    Component: ({ data, ...rest }) => (
                      <TableCell sx={{ px: '0 !important' }}>
                        <Link to={`./${data.companyId}`} state={data}>
                          <Button
                            {...sharedProps.button.table}
                            startIcon={<People />}
                          >
                            Users
                          </Button>
                        </Link>
                      </TableCell>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </Card>
      <Drawer open={searchParams.hasOwnProperty('addCompany')}>
        <AddCompanyForm
          values={{
            companyType: 'distributor',
            distributorCompanyId: userInfo.companyId,
          }}
        />
      </Drawer>
    </>
  )

  return params.branchId || 'No branch ID'
}
