import { Add, Link, PlaylistAdd, Search } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TextField,
  Typography,
} from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { enqueueSnackbar } from 'notistack'
// import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import { Outlet } from 'react-router-dom'
import client from '~/client'
import usePageQuery from '~/client/usePageQuery'
import AutoTable, {
  DateCell,
  DeleteCell,
  EditCell,
} from '~/components/Data/AutoTable'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import PageHeader from '~/components/Layouts/PageHeader'
import sharedProps from '~/components/sharedProps'
import useSearchParams from '../../../../core/router/useSearchParams'
import Drawer from '../../../../components/Layouts/Drawer'
import flexSplitSx from '../../../../core/sx/flexSplitSx'
import AddCompanyForm from '../../../../components/Companies/AddCompanyForm'

const findAttr = (item, key) =>
  item.Attributes.find(attr => attr.Name === key)?.Value || ''

export default function CompanyRelations({ mode = 'distributor' }) {
  const userInfo = client.getUserInfo()

  // const isAdmin = userInfo.role.includes('admin')
  const isAdmin = client.hasRoles(['rep_admin', 'rep_sales_manager'])

  const entityType =
    mode === 'distributor'
      ? { name: 'distributor', title: 'distributor' }
      : { name: 'contractingFirm', title: 'contracting firm' }
  const [relationshipPinCode, setRelationshipPinCode] = React.useState('')
  const [company, setCompany] = React.useState(null)
  const [isChecking, setIsChecking] = React.useState(false)

  const handleChange = e => {
    setCompany(null)
    setRelationshipPinCode(e.target.value)
  }

  const { mutate, ...response } = usePageQuery(
    `companyRelations/${userInfo.companyId}`
  )

  const handleCheckClick = async () => {
    try {
      setIsChecking(true)
      const res = await client.get(
        `companyRelations/?pinCode=${relationshipPinCode}`
      )
      console.log(res)
      setCompany(res)
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setIsChecking(false)
    }
  }

  const [isAdding, setIsAdding] = React.useState(false)
  const handleAddClick = async () => {
    try {
      setIsAdding(true)
      const res = await client.call('companyRelations', {
        relationshipPinCode,
      })
      mutate()
      setRelationshipPinCode('')
      setCompany(null)
      enqueueSnackbar(
        `${res.companyName || 'Company'} added as a ${entityType.title}`,
        {
          variant: 'success',
        }
      )
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setIsAdding(false)
    }
  }

  const handleDelete = async ({ companyRelationId }) => {
    console.log('handleDelete', companyRelationId)
    await client.put(`companyRelations`, {
      companyRelationId,
      status: 'disabled',
    })
    mutate()
  }

  const handleEdit = async ({ companyRelationId }) => {
    const input = prompt('Enter discount %')
    const discount = parseFloat(input)

    if (input === null) return

    if (isNaN(discount) || discount > 100 || discount < 0) {
      enqueueSnackbar('Invalid discount', { variant: 'error' })
      return
    }

    const res = await client.put('companyRelations', {
      companyRelationId,
      discount,
    })
    mutate()

    console.log('companyRelations res', res)
    enqueueSnackbar('Discount updated', { variant: 'success' })
  }

  const [params, setParams] = useSearchParams()

  return (
    <>
      <PageHeader />

      <Card sx={{ p: 2.5 }}>
        <Box sx={{ ...flexSplitSx, mb: 2 }}>
          <Button
            endIcon={<Link />}
            onClick={() => setParams(p => ({ ...p, pair: true }))}
          >
            Pair with existing {entityType.title}
          </Button>

          {client.hasRoles([
            'distributor_admin',
            'distributor_user',
            'unico_admin',
          ]) && (
            <Button
              endIcon={<Add />}
              onClick={() => setParams(p => ({ ...p, addCompany: '' }))}
            >
              Add new {entityType.title}
            </Button>
          )}
        </Box>

        <AutoTable
          {...response}
          data={response?.data}
          columns={[
            { field: `${entityType.name}.companyName`, sortable: true },
            { field: 'createdAt', Component: DateCell, sortable: true },
            {
              field: 'createdBy',
              headerName: 'Created by',
              Component: ({ data }) => (
                <TableCell>
                  {data.createdBy?.firstName || ''}{' '}
                  {data.createdBy?.lastName || ''}
                </TableCell>
              ),
            },
            // { field: 'createdBy.firstName', headerName: 'Created by' },
            // { field: 'createdBy.lastName', headerName: '' },
            mode !== 'distributor' && {
              field: 'discount',
              sortable: true,
            },
            isAdmin &&
              mode !== 'distributor' && {
                field: 'edit',
                onClick: handleEdit,
                Component: EditCell,
              },
            // client.hasRoles('admin') &&
            {
              field: 'delete',
              onClick: handleDelete,
              Component: DeleteCell,
            },
          ]}
        />
      </Card>
      <Drawer open={params.hasOwnProperty('addCompany')}>
        <AddCompanyForm values={{ companyType: entityType.name }} />
      </Drawer>
      <Drawer open={params.hasOwnProperty('pair')}>
        <LoadingIndicators {...{ isLoading: isChecking || isAdding }} />

        <Typography variant="h4" sx={{ mb: 2 }}>
          Pair with {entityType.title}
        </Typography>

        <Stack
          direction="column"
          spacing={2}
          sx={{ alignItems: 'flex-start', mb: 3 }}
        >
          <Typography>
            Speak to your {entityType.title} to get their code. Enter the code
            below to access their pricing and support.
          </Typography>
          <TextField
            // type="number"
            title={`${entityType.title} code`}
            name="relationshipPinCode"
            value={relationshipPinCode}
            onChange={handleChange}
          />
          <SubmitButton
            isSubmitting={isChecking}
            onClick={handleCheckClick}
            disabled={relationshipPinCode.length < 6}
            variant="outlined"
            endIcon={<Search />}
          >
            Check for {entityType.title}
          </SubmitButton>
        </Stack>

        {company && (
          <Stack>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {company.companyName}
            </Typography>

            <Typography sx={{ mb: 2 }}>
              Address:
              {[
                company.address?.line1,
                company.address?.state,
                company.address?.zipCode,
              ].join(', ')}
            </Typography>

            {company.website && (
              <Typography sx={{ mb: 2 }}>
                Website:{' '}
                <a href={`https://${company.website}`} target="blank">
                  {company.website}
                </a>
              </Typography>
            )}

            {company?.companyType === mode ? (
              <SubmitButton
                isSubmitting={isAdding}
                onClick={handleAddClick}
                endIcon={<PlaylistAdd />}
              >
                Add {entityType.title}
              </SubmitButton>
            ) : (
              <Typography>
                This company isn't a {entityType.title} so you can't add it.
              </Typography>
            )}
          </Stack>
        )}
      </Drawer>

      <Outlet />
    </>
  )
}
