import { Box, Button, Card, Typography } from '@mui/material'
import client from '~/client'
import CompaniesTable from '~/components/Companies/CompaniesTable'
import PageHeader from '~/components/Layouts/PageHeader'
import UsersTable from '~/components/Users/UsersTable'
import EditUser from '../../../../components/Users/EditUser'
import Drawer from '../../../../components/Layouts/Drawer'
import useSearchParams from '../../../../core/router/useSearchParams'
import { useNavigate } from 'react-router-dom'
import DeleteUser from '../../../../components/Users/DeleteUser'
import flexSplitSx from '../../../../core/sx/flexSplitSx'
import { PersonAdd } from '@mui/icons-material'
import AddNewUser from '../../../../components/Users/AddNewUser'

export default function ContractorNetworkUsers() {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()

  return (
    <>
      <PageHeader />
      <Card sx={{ p: 2.5 }}>
        <Box sx={flexSplitSx}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Users
          </Typography>
          {client.hasRoles([
            'rep_admin',
            'rep_sales_manager',
            'distributor_user',
            'distributor_admin',
          ]) && (
            <Button
              onClick={() =>
                navigate(`?addUser`, { state: { user: { companyId: '' } } })
              }
              endIcon={<PersonAdd />}
            >
              Add user
            </Button>
          )}
        </Box>

        <UsersTable
          query="repCompanyRelations/users"
          onEditClick={user =>
            navigate(`?editUser=${user.userId}`, { state: user })
          }
          onDeleteClick={user =>
            navigate(`?deleteUser=${user.userId}`, { state: user })
          }
        />

        <Drawer open={params.hasOwnProperty('addUser')}>
          <AddNewUser companyType="contractingFirm" />
        </Drawer>

        <Drawer open={params.editUser}>
          <EditUser />
        </Drawer>

        <Drawer open={params.deleteUser}>
          <DeleteUser />
        </Drawer>
      </Card>
    </>
  )
}
