import { PersonAdd } from '@mui/icons-material'
import { Box, Button, Card, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import client from '~/client'
import Drawer from '~/components/Layouts/Drawer'
import PageHeader from '~/components/Layouts/PageHeader'
import AddNewUser from '~/components/Users/AddNewUser'
import DeleteUser from '~/components/Users/DeleteUser'
import EditUser from '~/components/Users/EditUser'
import UsersTable from '~/components/Users/UsersTable'
import useSearchParams from '~/core/router/useSearchParams'
import flexSplitSx from '~/core/sx/flexSplitSx'

export default function ContractingFirmUsers() {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()
  const { contractingFirmId } = useParams()

  return (
    <>
      <PageHeader />
      <Card sx={{ p: 2.5 }}>
        <Box sx={flexSplitSx}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Users
          </Typography>
          {client.hasRoles([
            'rep_admin',
            'rep_sales_manager',
            'distributor_user',
            'distributor_admin',
          ]) && (
            <Button
              onClick={() =>
                navigate(`?addUser`, {
                  state: { user: { companyId: contractingFirmId } },
                })
              }
              endIcon={<PersonAdd />}
            >
              Add user
            </Button>
          )}
        </Box>

        <UsersTable
          disableEdit
          query={`companyusers/?companyId=${contractingFirmId}`}
          // query={`companyusers/${contractingFirmId}`}
          onEditClick={user =>
            navigate(`?editUser=${user.userId}`, { state: user })
          }
          onDeleteClick={user =>
            navigate(`?deleteUser=${user.userId}`, { state: user })
          }
        />

        <Drawer open={params.hasOwnProperty('addUser')}>
          <AddNewUser companyType="contractingFirm" />
        </Drawer>

        <Drawer open={params.editUser}>
          <EditUser />
        </Drawer>

        <Drawer open={params.deleteUser}>
          <DeleteUser />
        </Drawer>
      </Card>
    </>
  )
}
