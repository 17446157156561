import { createTheme } from '@mui/material/styles'
import FormHelperText from '~/core/forms/theme/FormHelperText'
import * as colors from './colors'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'

const dialogPadding = 20

const theme = createTheme({
  custom: { colors },
  // palette: {
  //   primary: {
  //     main: '#17609e',
  //   },
  //   secondary: {
  //     main: '#7ceff3',
  //   },
  // },
  palette,
  shadows,
  shape: {
    borderRadius: 8,
  },
  // shadows: [
  //   'none',
  //   // '0px 4px 6px rgba(0, 0, 0, 0.12);',
  //   // '0px 4px 10px rgba(0, 0, 0, 0.23);',
  //   // '0px 4px 20px rgba(0, 0, 0, 0.23);',
  // ],
  typography,
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontWeight: 600,
          textDecoration: 'underline',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary',
        disableElevation: true,
      },
      styleOverrides: {
        root: { padding: 16, paddingTop: 8, paddingBottom: 8, borderRadius: 4 },
        outlined: {
          borderWidth: 2,
          '&:hover': { borderWidth: 2 },
          '&.Mui-disabled': { borderWidth: 2 },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      // styleOverrides: {
      //   root: {
      //     borderRadius: 4,
      //   },
      // },
    },

    MuiToggleButton: {
      // works
      styleOverrides: {
        root: {
          // borderWidth: '2px !important',
          // '&.Mui-selected': {
          //   border: '2px solid red',
          // },
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       '&.MuiInputBase-inputSizeSmall': {
    //         padding: 0,
    //       },
    //     },
    //   },
    // },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&&& > fieldset': {
            borderWidth: '2px !important',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& tbody tr:last-child td, & tbody tr:last-child th': { border: 0 },
          '& td:last-child, & th: last-child': { paddingRight: 8 },
          '& td:first-child, & th: first-child': { paddingLeft: 8 },
          '& tr:[onclick]:hover': {
            background: '#f7f7f7',
            cursor: 'pointer',
          },
        },
      },
    },
    // MuiTableContainer: {
    //   styleOverrides: {
    //     root: {
    //       maxWidth: 500,
    //     },
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          '& .MuiInputAdornment-positionEnd': {
            alignSelf: 'start',
            height: '100%',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
    MuiGrid: {
      defaultProps: {
        xs: 12,
      },
      styleOverrides: {
        container: {
          '&': { maxWidth: 'calc(100% + 24px)' },
        },
        item: {
          // TODO: problems
          // '&': { maxWidth: '100%' },
        },
      },
    },
    MuiGrid2: {
      defaultProps: {
        xs: 12,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { display: 'flex', alignItems: 'center', '& > a': { order: 2 } },
        asterisk: { color: 'red', order: 1 },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'rgba(29, 88, 154, 1)',
        },
      },
    },
    // MuiToggleButtonGroup: {
    //   styleOverrides: {
    //     root: { flexWrap: 'wrap' },
    //     grouped: ({ theme }) => ({
    //       '&:not(:last-of-type)': {
    //         marginRight: 8,
    //       },
    //       marginBottom: 8,
    //       border: '2px solid rgba(0, 0, 0, 0.12) !important',
    //       // border: '2px solid rgba(0, 0, 0, 0.12)',
    //       borderRadius: '4px !important',
    //       '&.Mui-selected': {
    //         border: `2px solid ${alpha(
    //           theme.palette.secondary.main,
    //           0.7
    //         )} !important`,
    //       },
    //       '.Mui-error + &': {
    //         border: `2px solid ${alpha(
    //           theme.palette.error.dark,
    //           0.6
    //         )} !important`,
    //       },
    //     }),
    //   },
    // },
    // MuiFormHelperText: {
    //   defaultProps: {
    //     as: motion.div,
    //     initial: { height: 0 },
    //     animate: { height: 'auto' },
    //     exit: { height: 0 },
    //   },
    // },
    MuiCssBaseline: {
      // styleOverrides: (themeParam) => ({
      //   body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
      // }),
      styleOverrides: ({
        typography: { body1, a, h1, h2, h3, h4, h5, h6 },
        fontFamily,
      }) => ({
        a,
        p: body1,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        html: {
          scrollBehavior: 'smooth',
        },
        body: {
          background: '#F9F9FC',
          '& header': {},
          '& footer': {
            background: colors.charcoal,
            color: 'white',
          },
        },
        'p, h1, h2, h3, h4, h5, h6': {
          margin: 0,
          // width: '100%',
          // marginBottom: 16,
          marginBottom: '0.35em',
        },
        button: {
          border: 'none',
          cursor: 'pointer',
          background: 'transparent',
        },
        // '*': { boxSixing: 'border-box' },
      }),
    },
    MuiSnackbar: {
      defaultProps: {
        variant: 'info',
      },
      styleOverrides: {
        root: {
          '& .super': {
            background: 'purple',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {},
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        as: FormHelperText,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 20,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 20,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 20,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: { tooltip: { fontSize: 13 } },
    },
  },
})

typeof window !== 'undefined' && console.log('theme', theme)

export default theme
