import { Button, Card, Stack, TableCell } from '@mui/material'
import AddCompanyForm from '~/components/Companies/AddCompanyForm'
import EditCompanyForm from '~/components/Companies/EditCompanyForm'
import Drawer from '~/components/Layouts/Drawer'
import PageHeader from '~/components/Layouts/PageHeader'
import Link from '~/config/Link'
import useSubmatch from '~/core/router/useSubmatch'
// import AddCompanyForm from './AddCompanyForm'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import { TextFieldElement } from 'react-hook-form-mui'
import AutoTable, {
  AddressCell,
  ChipCell,
  UsersCell,
} from '~/components/Data/AutoTable'
import SearchContainer from '~/components/Data/Filter/SearchContainer'
import { useContractorNetworkCompanies } from '../../../../components/Companies/useNetworkCompanies'
import sharedProps from '../../../../components/sharedProps'
import { People } from '@mui/icons-material'
import CreatePairingModal from '../../Company/Relations/CreatePairingModal'
import client from '~/client'

export default function ContractorNetworkContractingFirms({}) {
  const [search, setSearch] = React.useState()

  const { data, ...response } = useContractorNetworkCompanies({ search })

  return (
    <>
      <PageHeader />
      <Stack
        direction="row"
        spacing={3}
        sx={{ justifyContent: 'space-between', mb: 3 }}
      >
        {client.hasRoles(['distributor_admin', 'distributor_user']) && (
          <CreatePairingModal mode="contractingFirm" />
        )}
        <Link to="add">
          <Button variant="outlined" endIcon={<AddIcon />}>
            Add new company
          </Button>
        </Link>
      </Stack>
      <Card sx={{ position: 'relative', p: 2 }}>
        <SearchContainer setSearch={setSearch}>
          <TextFieldElement label="Company name" name="companyName" />
        </SearchContainer>
        <AutoTable
          {...response}
          data={data}
          titleField="companyName"
          uniqueField="companyId"
          columns={[
            'companyName',
            { field: 'companyType', Component: ChipCell },
            { headerName: 'Address', Component: AddressCell },
            {
              Component: props => (
                <TableCell sx={{ px: '0 !important' }}>
                  <Link
                    to={`../company-users/${props.data.companyId}`}
                    state={props.data}
                  >
                    <Button
                      {...sharedProps.button.table}
                      startIcon={<People />}
                    >
                      Users
                    </Button>
                  </Link>
                </TableCell>
              ),
              field: 'editUsers',
            },
          ]}
        />
      </Card>
      <Drawer open={useSubmatch('add')}>
        <AddCompanyForm />
      </Drawer>
      <Drawer open={useSubmatch('edit')}>
        <EditCompanyForm />
      </Drawer>
    </>
  )
}
