import { useSWRConfig } from 'swr'
import client from '~/client'

export default function useMutateCompanies() {
  const { mutate } = useSWRConfig()

  return () =>
    mutate(
      key =>
        typeof key === 'string' &&
        [
          'company',
          'repCompanyRelations/companies',
          'distributorCompanyRelations/companies',
          'companyRelations',
          'contractor-network',
        ].some(string => key.startsWith(string)),
      undefined,
      { revalidate: true }
    )
}
