import React from 'react'
import useSWR from 'swr'
import client from '~/client'

// const meta = {
//   nextPageId: 0,
//   totalItems: 0,
//   totalPages: 0,
// }

export const defaultOptions = {
  // revalidateIfStale: false,
  revalidateOnFocus: false,
  // revalidateOnReconnect: false,
  keepPreviousData: true,
  errorRetryCount: 2,
  dedupingInterval: 15000,
}

// TODO: make easier api for this
export const formatPageQuery = (query, pageSize, nextPageId, sort, search) => {
  if (!query) return null
  const char = query.includes('?') ? '&' : '?'
  const p = pageSize ? `pageSize=${pageSize}` : ''
  const n = nextPageId ? `&nextPageId=${nextPageId}` : ''
  const s = sort?.field
    ? `&sort=${sort.field}&order=${sort.order || 'asc'}`
    : ''
  const q =
    Object.keys(search || {}).length > 0
      ? `&search=${encodeURIComponent(JSON.stringify(search))}`
      : ''
  return `${query}${char}${p}${n}${s}${q}`
}

export default function usePageQuery(
  query,
  { pageSize = 10, initPage = 0, sorting, search, ...options } = {}
) {
  const [page, setPage] = React.useState(initPage)
  const [sort, setSort] = React.useState(sorting)
  const onSortClick = field =>
    setSort(sort => ({
      field,
      order: sort?.field === field && sort?.order === 'asc' ? 'desc' : 'asc',
    }))

  const {
    data: { data, meta } = {},
    mutate,
    ...rest
  } = useSWR(
    formatPageQuery(query, pageSize, page, sort, search),
    client.page,
    {
      ...defaultOptions,
      ...options,
      // use: [laggy],
    }
  )

  React.useEffect(() => void setPage(initPage), [search, setPage])

  // this unwraps data from the combined data/meta tag to avoid rewriting these everywhere
  const nestedMutate = (fn, options = {}) =>
    mutate(fn, {
      ...options,
      optimisticData:
        typeof options.optimisticData === 'function'
          ? ({ data, ...meta }) => ({
              data: options.optimisticData(data),
              ...meta,
            })
          : typeof options.optimisticData !== 'undefined'
          ? ({ data, ...meta }) => ({ data: options.optimisticData, ...meta })
          : undefined,
      populateCache:
        typeof options.populateCache === 'function'
          ? (newDatum, { data, ...meta }) => ({
              data: options.populateCache(newDatum, data),
              ...meta,
            })
          : options.populateCache,
    })

  const isNextPage = meta?.totalItems > (page + 1) * pageSize

  const isPrevPage = page !== 0

  const onNextClick = () => isNextPage && setPage(p => p + 1)

  const onPrevClick = () => setPage(p => Math.max(0, p))

  useSWR(
    formatPageQuery(query, pageSize, page + 1),
    client.page
    // options
  )

  return {
    pagination: {
      page,
      setPage,
      onNextClick,
      onPrevClick,
      isNextPage,
      isPrevPage,
      ...meta,
    },
    sort: {
      ...sort,
      onSortClick,
      setSort,
    },
    mutate: nestedMutate,
    data,
    ...rest,
  }
}
