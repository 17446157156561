// import Grid from '@mui/material/Unstable_Grid2/Grid2'
// import Table from '@mui/material/Table'
import { People } from '@mui/icons-material'
import { Button } from '@mui/material'
import AutoTable, {
  ChipCell,
  DisableCell,
  EditCell,
  TableCell,
} from '~/components/Data/AutoTable'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import useCompanies from '~/components/Companies/useCompanies'
import SearchContainer from '~/components/Data/Filter/SearchContainer'
import CompanySearchFields from '~/components/Data/Filter/CompanySearchFields'
import React from 'react'

export default function CompaniesTable({ query }) {
  // const userInfo = client.getUserInfo()
  // TODO change this

  const [search, setSearch] = React.useState()
  const { deleteCompany, ...response } = useCompanies({ query, search })

  return (
    <>
      <SearchContainer setSearch={setSearch}>
        <CompanySearchFields />
      </SearchContainer>
      <AutoTable
        {...response}
        titleField="companyName"
        uniqueField="companyId"
        columns={[
          'companyName',
          'markupRate',
          // 'distributorMultiplier',
          'relationshipPinCode',
          {
            field: 'distributorCompany.companyName',
            headerName: 'Distro parent',
            Component: ({ data, ...rest }) => (
              <TableCell sx={{ px: '0 !important' }}>
                {data?.distributorCompany?.companyName}
              </TableCell>
            ),
          },
          { field: 'companyType', Component: ChipCell },
          { field: 'status', Component: ChipCell },
          {
            field: 'edit',
            Component: EditCell,
          },
          {
            field: 'editUsers',
            Component: ({ data, ...rest }) => (
              <TableCell sx={{ px: '0 !important' }}>
                <Link to="../users" state={data}>
                  <Button {...sharedProps.button.table} startIcon={<People />}>
                    Users
                  </Button>
                </Link>
              </TableCell>
            ),
          },
          {
            field: 'disable',
            Component: DisableCell,
            width: 0.5,
            onClick: deleteCompany,
          },
        ]}
      />
    </>
  )
}
